import React, { Component, Fragment } from 'react';
import Navigation from './Navigation';
import getFirebase from './Firebase';
import FirebaseContext from './Firebase/FirebaseContext';
import TrainingsContext from '../BuisenessLogic/TrainingsContext';
import EventsContext from '../BuisenessLogic/EventsContext';
import getTrainings from '../BuisenessLogic/Trainings';
import getEvents from '../BuisenessLogic/Events';
import withAuthentication from './Session/withAuthentication';
import Helmet from '../components/Helmet';
import Footer from '../components/MainPage/Footer';

class Layout extends Component {
  state = {
    firebase: null
  };

  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/firestore');

    Promise.all([app, auth, database]).then(values => {
      const firebase = getFirebase(values[0]);
      const trainings = getTrainings(firebase.store);
      const events = getEvents(firebase.store);
      this.setState({ firebase, trainings, events });
    });
  }

  render() {
    return (
      <EventsContext.Provider value={this.state.events}>
        <TrainingsContext.Provider value={this.state.trainings}>
          <FirebaseContext.Provider value={this.state.firebase}>
            <Helmet {...this.props} />
            <AppWithAuthentication {...this.props} />
          </FirebaseContext.Provider>
        </TrainingsContext.Provider>
      </EventsContext.Provider>
    );
  }
}

const AppWithAuthentication = withAuthentication(props => (
  <Fragment>
    {!props.layoutLess && <Navigation />}
    {props.children}
    {!props.layoutLess && <Footer />}
  </Fragment>
));

export default Layout;
