import React, { Component } from 'react';
import { withPrefix, Link } from 'gatsby';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-4 col-md-2 footer-logo">
              <a href="/" className="logo">
                <img src={withPrefix('assets/img/volovod-logo.svg')} alt="Volovod" />
              </a>
            </div>
            <div className="col-sm-12 col-md-10 col-lg-6">
              <nav className="footer-nav">
                <ul className="footer-menu">
                  <li>
                    <Link to="/team">Про команду</Link>
                  </li>
                  <li>
                    <Link to="/trainings">Тренування</Link>
                  </li>
                  <li>
                    <Link to="/events">Змагання</Link>
                  </li>
                  <li>
                    <Link to="/rating">Рейтинг</Link>
                  </li>
                </ul>
                <ul className="footer-menu">
                  {/*<li>*/}
                  {/*  <a href="https://cyclinghouse.com.ua">Веломайстерня Сycling House</a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <a href="http://sis.in.ua">Магазин SIS</a>*/}
                  {/*</li>*/}
                  <li>
                    <Link to="/agreement">Договір оферти</Link>
                  </li>
                  <li>
                    <Link to="/userAgreement">Користувацька угода</Link>
                  </li>
                  <li>
                    <Link to="/contacts">Контакти</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-sm-10 col-md-8 offset-md-3 col-lg-4 offset-lg-0 mb-5 mb-lg-0">
              <h3 className="left-border title">Спонсори</h3>
              <div className="sponsors">
                <a href="https://sigma.software/" target="_blank">
                  <img src={withPrefix('assets/img/symbol-negative.svg')} alt="Sigma Software" />
                </a>
                <a href="https://unicorn.com/ua" target="_blank">
                  <img src={withPrefix('assets/img/unicorn-logo.svg')} alt="Unicorn" />
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-lg-2 copyright">
              <p>© Volovod.com, 2024</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
