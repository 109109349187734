import React from 'react';

const EventsContext = React.createContext(null);

export const withEvents = Component => props => (
  <EventsContext.Consumer>
    {events => (events ? <Component {...props} events={events} /> : null)}
  </EventsContext.Consumer>
);

export default EventsContext;
