class Events {
  constructor(firestore) {
    this.store = firestore;
  }

  getEvents = () => {
    const that = this;
    const todayMidnight = new Date(new Date().setHours(0));

    return this.store
      .collection('events')
      .where('date', '>', todayMidnight)
      .orderBy('date', 'asc')
      .get()
      .then(function(querySnapshot) {
        return Promise.all(
          querySnapshot.docs.map(doc => {
            const { title, date, participants, ...other } = doc.data();
            const promises = participants ? participants.map(id => that.getUser(id)) : [];
            return Promise.all(promises).then(users => {
              if (date) {
                return {
                  id: doc.id,
                  title,
                  start: date.toDate(),
                  allDay: true,
                  participants: users.filter(Boolean),
                  ...other
                };
              }
            });
          })
        );
      });
  };

  goToEvent = ({ id }, currentUser) => {
    const instance = this;
    let alreadyRegistred, refundAmount;
    this.store
      .collection('events')
      .doc(id)
      .get()
      .then(doc => {
        const data = doc.exists ? doc.data() : {};
        if (!data.participants) {
          data.participants = [];
        }
        if (data.participants.includes(currentUser)) {
          alreadyRegistred = true;
          return { ...data, participants: data.participants.filter(user => user !== currentUser) };
        } else {
          alreadyRegistred = false;
          return { ...data, participants: [...data.participants, currentUser] };
        }
      })
      .then(
        async ({ participants }) => {
          this.store
            .collection('events')
            .doc(id)
            .set(
              {
                participants
              },
              { merge: true }
            )
            .then(function() {
              console.log('Document successfully written!');
            })
            .catch(function(error) {
              console.error('Error writing document: ', error);
            });
        },
        () => {}
      );
  };

  registerToEvent = ({ id, priority, title, start }, currentUser) => {
    const instance = this;
    let alreadyRegistred, refundAmount;
    this.store
      .collection('events')
      .doc(id)
      .get()
      .then(doc => {
        const data = doc.exists ? doc.data() : {};
        if (!data.registredParticipants) {
          data.registredParticipants = [];
        }
        if (data.registredParticipants.includes(currentUser)) {
          alreadyRegistred = true;
          return {
            ...data,
            registredParticipants: data.registredParticipants.filter(user => user !== currentUser)
          };
        } else {
          alreadyRegistred = false;
          return { ...data, registredParticipants: [...data.registredParticipants, currentUser] };
        }
      })
      .then(
        async ({ registredParticipants }) => {
          this.store
            .collection('events')
            .doc(id)
            .set(
              {
                registredParticipants
              },
              { merge: true }
            )
            .then(function() {
              console.log('Document successfully written!');
            })
            .catch(function(error) {
              console.error('Error writing document: ', error);
            });
        },
        () => {}
      )
      .then(() => {
        this.getUserWithId(currentUser).then(({ id, user }) => {
          const ratingInc = 5;
          const rating = (parseInt(user.rating) || 0) + ratingInc;

          this.writeRatingLog({
            user: user.id,
            rating,
            ratingInc,
            date: start,
            comment: `За посещение соревнования ${title}`
          });
          this.store
            .collection('users')
            .doc(currentUser)
            .set(
              {
                rating
              },
              { merge: true }
            );
        });
      });
  };

  writeRatingLog = logRecord => {
    this.store.collection('ratingLogs').add(logRecord);
  };

  getUserWithId = id =>
    this.store
      .collection('users')
      .where('id', '==', id)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0 && querySnapshot.docs[0].data()) {
          return { user: querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
        }
      });

  createEvent = event => {
    this.store.collection('events').add(event);
  };

  getUser = id =>
    this.store
      .collection('users')
      .where('id', '==', id)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0 && querySnapshot.docs[0].data()) {
          return querySnapshot.docs[0].data();
        }
      });

  editEvent = event => {
    const { id, freeBikes, time } = event;
    this.store
      .collection('events')
      .doc(id)
      .set(
        {
          //  Date: time,
          freeBikes
        },
        { merge: true }
      );
  };

  writeLog = (msg, income = 0) => {
    const logRecord = {
      date: Date.now(),
      msg,
      income
    };
    this.store.collection('logs').add(logRecord);
  };
}

let events;

function getEvents(firestore) {
  if (events) {
    return events;
  }

  events = new Events(firestore);

  return events;
}

export default getEvents;
