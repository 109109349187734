import moment from 'moment';
import swal from 'sweetalert';

const DEFAULT_AMOUNT_OF_BIKES = 10;
const TRAIN_COST = 200;
const CREDIT_LIMIT = 0;
export const ABON_PRICE = 1800;

export const getAdjustedPrice = (price, date) => {
  if (date) {
    return moment().isAfter(moment(date).subtract(24, 'hours')) ? price * 1.25 : price;
  }
  return price;
};

class Trainings {
  constructor(firestore) {
    this.store = firestore;
  }

  getTrainings = () => {
    const that = this;
    const todayMidnight = new Date(new Date().setHours(0));
    return this.store
      .collection('trainings')
      .where('active', '==', true)
      .where('Date', '>', todayMidnight)
      .orderBy('Date', 'asc')
      .get()
      .then(function(querySnapshot) {
        return Promise.all(
          querySnapshot.docs.map(doc => {
            const { name, Date: date, participants, freeBikes, ...other } = doc.data();
            const promises = participants ? participants.map(id => that.getUser(id)) : [];
            return Promise.all(promises).then(users => {
              if (date) {
                return {
                  id: doc.id,
                  title: name,
                  start: date.toDate(),
                  end: moment(date.toDate())
                    .add(1, 'h')
                    .toDate(),
                  allDay: false,
                  resource: users.filter(Boolean),
                  places: (freeBikes || DEFAULT_AMOUNT_OF_BIKES) - participants.length,
                  actual: date.toDate().getTime() > new Date().getTime(),
                  ...other
                };
              }
            });
          })
        );
      });
  };

  getGoods = () => {
    return this.store
      .collection('goods')
      .where('active', '==', true)
      .get()
      .then(function(querySnapshot) {
        return Promise.all(
          querySnapshot.docs.map(doc => {
            const id = doc.id;
            const data = doc.data();
            return { id, ...data };
          })
        );
      });
  };

  addGood = () => {
    const good = {
      active: true,
      name: '',
      description: '',
      fullDescription: '',
      img: '/sis/',
      price: '',
      slug: '',
      teamChoice: false,
      costPrice: 0,
      sale: false
    };
    this.store.collection('goods').add(good);
  };

  goToCyprus = currentUser => {
    this.getUserWithId(currentUser).then(({ user, id }) => {
      const rating = (parseInt(user.rating) || 0) + 20;
      this.store
        .collection('users')
        .doc(id)
        .set(
          {
            rating,
            cyprus20: true
          },
          { merge: true }
        );
    });
  };

  cancelCyprus = currentUser => {
    this.getUserWithId(currentUser).then(({ user, id }) => {
      const rating = (parseInt(user.rating) || 0) - 20;
      this.store
        .collection('users')
        .doc(id)
        .set(
          {
            rating,
            cyprus20: false
          },
          { merge: true }
        );
    });
  };

  goToTrain = ({ id }, currentUser) => {
    const instance = this;
    let alreadyRegistred, cancelRefund, refundAmount;
    this.store
      .collection('trainings')
      .doc(id)
      .get()
      .then(doc => {
        const data = doc.exists ? doc.data() : {};
        if (!data.participants) {
          data.participants = [];
        }
        if (data.participants.includes(currentUser)) {
          alreadyRegistred = true;
          return { ...data, participants: data.participants.filter(user => user !== currentUser) };
        } else {
          alreadyRegistred = false;
          return { ...data, participants: [...data.participants, currentUser] };
        }
      })
      .then(
        async ({ participants, Date, price = TRAIN_COST, name }) => {
          if (!name) {
            return;
          }

          const adjustedPrice = getAdjustedPrice(price, Date.toDate());

          if (!alreadyRegistred) {
            const enoughMoneyOrAboniment = await this.isUserHasMoneyOrAboniment(
              currentUser,
              adjustedPrice,
              CREDIT_LIMIT,
              Date
            );
            if (!enoughMoneyOrAboniment) {
              swal('Недостатньо грошей', {
                dangerMode: true
              });
              return;
            }
          } else {
            if (moment(Date.toDate()).isBetween(moment(), moment().add(12, 'hours'))) {
              swal({
                title: 'Повернення менш, ніж за 12 годин до тренування, неможливе',
                dangerMode: true
              });
              return;
            }
            refundAmount = price;
            if (moment(Date.toDate()).isBetween(moment(), moment().add(1, 'days'))) {
              refundAmount = price * 0.5;
              cancelRefund = await swal({
                title: 'Увага',
                text: `Ви робите скасування за добу до тренування, сума повернення: ${refundAmount} грн`,
                icon: 'warning',
                buttons: true,
                dangerMode: true
              }).then(refund => {
                if (refund) {
                  swal('Повернення зроблено', {
                    icon: 'success'
                  });
                  return false;
                } else {
                  swal('Уперед на тренування ;)');
                  return true;
                }
              });
            }
          }

          if (cancelRefund) {
            return;
          }

          this.store
            .collection('trainings')
            .doc(id)
            .set(
              {
                participants
              },
              { merge: true }
            )
            .then(function() {
              alreadyRegistred
                ? instance.refund(currentUser, refundAmount, Date.toDate())
                : instance.pay(currentUser, adjustedPrice, Date.toDate());
            })
            .catch(error => {
              console.error('Error writing document: ', error);
              this.writeLog(`ПОМИЛКА! під час запису на тренування! ${error}`, 0, currentUser);
            });
        },
        () => {}
      );
  };

  isUserHasMoneyOrAboniment = (currentUser, amount, credit, Date) => {
    const requiredAmount = amount - credit;
    const trainDate = moment(Date.toDate());
    return this.getUser(currentUser).then(({ money, abonement }) => {
      const validAboniment = abonement && moment(abonement.toDate()).isAfter(trainDate);
      return parseInt(money) >= requiredAmount || validAboniment;
    });
  };

  getUserWithId = id =>
    this.store
      .collection('users')
      .where('id', '==', id)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0 && querySnapshot.docs[0].data()) {
          return { user: querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
        }
      });

  pay = (currentUser, price = TRAIN_COST, date) => {
    this.getUserWithId(currentUser).then(({ user, id }) => {
      const money = (parseInt(user.money) || 0) - price;
      const rating = (parseInt(user.rating) || 0) + 1;
      const validAboniment = user.abonement && moment(user.abonement.toDate()).isAfter(date);
      this.writeRatingLog({
        user: user.id,
        rating,
        ratingInc: 1,
        date,
        comment: 'відвідування тренування'
      });
      const msg = !validAboniment
        ? `Користувач ${user.username}, що мав ${parseInt(user.money) ||
            0} грн, зареєструвався на тренування, і тепер має ${money} грн`
        : `Користувач ${
            user.username
          } відмітився на заняття, використавши абонемент, чинний до ${moment(
            user.abonement.toDate()
          ).format('DD-MM-YY')}`;
      this.writeLog(msg, 0, user.id);
      const dataSet = validAboniment ? { rating } : { money, rating };
      this.store
        .collection('users')
        .doc(id)
        .set(dataSet, { merge: true });
    });
  };

  refund = (currentUser, refundAmount = TRAIN_COST, date) => {
    this.getUserWithId(currentUser).then(({ user, id }) => {
      const validAboniment = user.abonement && moment(user.abonement.toDate()).isAfter(date);
      const adjustedRefundAmount = validAboniment ? 0 : parseInt(refundAmount);
      const money = (parseInt(user.money) || 0) + adjustedRefundAmount;
      const rating = (parseInt(user.rating) || 0) - 1;
      this.writeRatingLog({
        user: user.id,
        rating,
        ratingInc: -1,
        date,
        comment: 'скасування тренування'
      });

      this.writeLog(
        `Користувач ${
          user.username
        } зробив повернення на суму ${adjustedRefundAmount} грн, і тепер має ${money} грн`,
        -adjustedRefundAmount,
        user.id
      );
      this.store
        .collection('users')
        .doc(id)
        .set(
          {
            money,
            rating
          },
          { merge: true }
        );
    });
  };

  createTrain = train => {
    this.store.collection('trainings').add(train);

    try {
      const emailList = [
        '9276677@gmail.com',
        'akhmetshyna@gmail.com',
        'anton.kernasovsky@gmail.com'
      ];
      const emails = emailList.join(';');

      const url = `https://us-central1-volovod-488b2.cloudfunctions.net/sendMail?dest=${emails}`;
      fetch(url, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          subject: 'Діма створив тренування)',
          html: JSON.stringify(train)
        })
      });
    } catch (e) {
      console.log(e);
    }
  };

  getUser = id =>
    this.store
      .collection('users')
      .where('id', '==', id)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0 && querySnapshot.docs[0].data()) {
          return querySnapshot.docs[0].data();
        }
      });

  setUserMoney = (id, money) => {
    this.getUserWithId(id).then(({ user, id }) => {
      const parsedMoney = user && user.money && parseInt(user.money);
      this.writeLog(
        `Установлено залишок для ${
          user.username
        }: ${money} грн. Попередній залишок: ${parsedMoney} грн`,
        money - parsedMoney,
        user.id
      );
      this.store
        .collection('users')
        .doc(id)
        .set(
          {
            money
          },
          { merge: true }
        );
    });
  };
  addRemark = (id, remark) => {
    this.getUserWithId(id).then(({ user, id }) => {
      this.store
        .collection('users')
        .doc(id)
        .set(
          {
            remark
          },
          { merge: true }
        );
    });
  };

  addUserMoney = (id, incMoney) => {
    this.getUserWithId(id).then(({ user, id }) => {
      const parsedMoney = user && user.money && parseInt(user.money);
      const money = parsedMoney + parseInt(incMoney);
      this.writeLog(
        `Додано гроші для ${
          user.username
        } на суму ${incMoney}. Загальна сума після поповнення: ${money} грн`,
        incMoney,
        user.id
      );
      this.store
        .collection('users')
        .doc(id)
        .set(
          {
            money
          },
          { merge: true }
        );
    });
  };

  addUserRating = (id, incRating) => {
    if (!incRating) {
      return;
    }
    this.getUserWithId(id).then(({ user, id }) => {
      const parsedRating = user && user.rating && parseInt(user.rating);
      const rating = parsedRating + parseInt(incRating);
      this.writeRatingLog({
        user: user.id,
        rating,
        ratingInc: parseInt(incRating),
        date: new Date(),
        comment: 'За фініші й тренування'
      });
      this.store
        .collection('users')
        .doc(id)
        .set(
          {
            rating
          },
          { merge: true }
        );
    });
  };

  removeTrain = id => {
    this.store
      .collection('trainings')
      .doc(id)
      .set(
        {
          active: false
        },
        { merge: true }
      );
  };

  editTrain = train => {
    const { id, ...args } = train;
    this.store
      .collection('trainings')
      .doc(id)
      .set(
        {
          ...args
        },
        { merge: true }
      );
  };

  writeLog = (msg, income = 0, userID = '') => {
    const logRecord = {
      date: Date.now(),
      msg,
      income,
      userID
    };
    this.store.collection('logs').add(logRecord);
  };

  writeRatingLog = logRecord => {
    this.store.collection('ratingLogs').add(logRecord);
  };

  getLogs = (limit = 50) => {
    return this.store
      .collection('logs')
      .orderBy('date', 'desc')
      .limit(limit)
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.docs.map(doc => doc.data());
      });
  };

  getRatingLogs = (userId, limit = 20) => {
    return this.store
      .collection('ratingLogs')
      .where('user', '==', userId)
      .orderBy('date', 'desc')
      .limit(limit)
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.docs.map(doc => doc.data());
      });
  };

  getTotalMoney = () => {
    return this.store
      .collection('users')
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.docs.map(doc => doc.data());
      });
  };
  buyAboniment = currentUser => {
    const abonement = moment()
      .add(30, 'days')
      .toDate();
    swal({
      title: 'Увага',
      text: `Ви купляєте абонемент на суму ${ABON_PRICE} грн, що діятиме до ${moment(
        abonement
      ).format('DD MMM YYYY')}`,
      icon: 'info',
      buttons: true,
      dangerMode: true
    }).then(
      accept => {
        if (!accept) {
          return;
        }
        this.getUserWithId(currentUser).then(({ user, id }) => {
          const money = parseInt(user.money) || 0;

          if (money >= ABON_PRICE) {
            const leftOver = money - ABON_PRICE;

            this.writeLog(
              `Користувач ${user.username} купив абонемент, що діє до ${moment(abonement).format(
                'DD-MM-YY'
              )}`,
              0,
              user.id
            );
            this.store
              .collection('users')
              .doc(id)
              .set({ money: leftOver, abonement }, { merge: true });
          }
        });
      },
      () => {}
    );
  };

  addReview = (review, goodId, currentUser) => {
    this.store.collection('reviews').add({
      text: review,
      goodId,
      date: new Date(),
      user: currentUser
    });
  };
  getReviews = goodId => {
    return this.store
      .collection('reviews')
      .where('goodId', '==', goodId)
      .orderBy('date', 'desc')
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.docs.map(doc => doc.data());
      });
  };
}

let trainings;

function getTrainings(firestore) {
  if (trainings) {
    return trainings;
  }

  trainings = new Trainings(firestore);

  return trainings;
}

export default getTrainings;
