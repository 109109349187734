import React from 'react';

const TrainingsContext = React.createContext(null);

export const withTrainings = Component => props => (
  <TrainingsContext.Consumer>
    {trainings => (trainings ? <Component {...props} trainings={trainings} /> : null)}
  </TrainingsContext.Consumer>
);

export default TrainingsContext;