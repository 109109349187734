export function mobilecheck() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
}

export const isAdmin = userData => userData && ADMINS.includes(userData.id);

const ADMINS = [
  'dlYSP0e7FcMcRr803Ww2M4ZOuck2',
  'iOTlhMsIEUS4uCZ1qAWAPJ1VEzm2',
  '4exSLu2TV8YY71fiTFWC6RHb0z32'
];
