import React from 'react';

import AuthUserContext from './AuthUserContext';
import { withFirebase } from '../Firebase/FirebaseContext';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        initFirebase: false,
        authUser: null
      };
    }

    firebaseInit = () => {
      if (this.props.firebase && !this.state.initFirebase) {
        this.props.firebase.auth.onAuthStateChanged(authUser => {
          authUser
            ? this.setState(() => ({ authUser, initFirebase: true }))
            : this.setState(() => ({
                authUser: null,
                initFirebase: true
              }));
        });
      }
    };

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
      if (this.state.authUser && !this.init) {
        this.init = true;
        this.props.firebase.base.listenToCollection('users', {
          context: this,
          state: 'bar',
          then() {
            this.props.firebase
              .getUser(this.state.authUser.uid)
              .then(userData => this.setState({ userData }));
          }
        });
      }
    }

    render() {
      const { authUser, userData } = this.state;
      return (
        <AuthUserContext.Provider value={{ authUser, userData }}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
