export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const PASSWORD_FORGET = '/pw-forget';
export const HOME = '/';
export const ACCOUNT = '/account';
export const CALENDAR = '/calendar';
export const RATING = '/rating';
export const ABOUT = '/about';
export const TRAININGS = '/trainings';
export const CYPRUS = '/cyprus';
export const CONTACTS = '/contacts';
export const AGREEMENT = '/userAgreement';
export const OFFERTA = '/agreement';
export const LOGS = '/logs';
export const EVENTS = '/events';
export const RENT = '/прокат';
export const RENT_CASE = '/прокат-кейса';
export const theory = '/must-read';
export const TEAM = '/team';
export const NUTRITION = '/nutrition';
