import { mobilecheck } from '../../utils';
import moment from 'moment';
const Rebase = require('re-base');

const prodConfig = {
  apiKey: 'AIzaSyCsa8ybS1GQ3ShgBTHdKODfQ0QmdJ5tFO4',
  authDomain: 'volovod-488b2.firebaseapp.com',
  databaseURL: 'https://volovod-488b2.firebaseio.com',
  projectId: 'volovod-488b2',
  storageBucket: 'volovod-488b2.appspot.com',
  messagingSenderId: '844132508134'
};

const devConfig = {
  apiKey: 'AIzaSyCsa8ybS1GQ3ShgBTHdKODfQ0QmdJ5tFO4',
  authDomain: 'volovod-488b2.firebaseapp.com',
  databaseURL: 'https://volovod-488b2.firebaseio.com',
  projectId: 'volovod-488b2',
  storageBucket: 'volovod-488b2.appspot.com',
  messagingSenderId: '844132508134'
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export class Firebase {
  constructor(firebase) {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    this.firebase = firebase;
    this.auth = firebase.auth();
    this.store = firebase.firestore();
    this.base = Rebase.createClass(this.store);
    const settings = { timestampsInSnapshots: true };
    this.store.settings(settings);
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Facebook Auth ***

  doSignInWithFacebook = () => {
    const provider = new this.firebase.auth.FacebookAuthProvider();
    return this.firebase.auth().signInWithRedirect(provider);
  };

  doSignInWithGoogle = () => {
    const provider = new this.firebase.auth.GoogleAuthProvider();
    return this.firebase.auth().signInWithPopup(provider);
  };

  // *** User API ***

  doCreateUser = (
    id,
    username,
    email,
    photoURL = 'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/1872865910.png?alt=media&token=2408952e-1559-42ea-be43-e01182434578'
  ) => {
    return this.store.collection('users').add({
      username,
      email,
      id,
      photoURL,
      money: 0
    });
  };

  onceGetUsers = () => this.store.collection('users').get();

  getUser = id =>
    this.store
      .collection('users')
      .where('id', '==', id)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0 && querySnapshot.docs[0].data()) {
          return querySnapshot.docs[0].data();
        }
        return false;
      });

  getLastMonthActivity = filterCriteria => {
    const monthAgo = moment()
      .subtract(1, 'month')
      .toDate();

    return this.store
      .collection('trainings')
      .where('Date', '<', new Date())
      .where('Date', '>', monthAgo)
      .where('participants', 'array-contains', filterCriteria)
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.docs.map(doc => doc.data());
      });
  };

  getLastMonthRating = userId => {
    const monthAgo = moment()
      .subtract(1, 'month')
      .toDate();

    return this.store
      .collection('ratingLogs')
      .where('user', '==', userId)
      .where('date', '>', monthAgo)
      .where('date', '<=', moment().toDate())
      .get()
      .then(function(querySnapshot) {
        return querySnapshot.docs.map(doc => doc.data());
      });
  };

  getUsers = filterCriteria => {
    if (filterCriteria) {
      return Promise.all([this.getUser(filterCriteria)]);
    }
    return this.store
      .collection('users')
      .orderBy('rating', 'desc')
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0 && querySnapshot.docs[0].data()) {
          return querySnapshot.docs.map(doc => doc.data()).filter(d => d.username);
        }
        return false;
      });
  };
}

let firebase;

function getFirebase(app, auth, firestore) {
  if (firebase) {
    return firebase;
  }

  firebase = new Firebase(app, auth, firestore);

  return firebase;
}

export default getFirebase;
