import React, { Fragment, Component } from 'react';
import { Link, withPrefix } from 'gatsby';

import * as routes from '../../constants/routes';
import AuthUserContext from '../Session/AuthUserContext';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { withFirebase } from '../Firebase/FirebaseContext';
import Avatar from '@material-ui/core/Avatar';
import { isAdmin } from '../../utils';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../about/scss/stylish-portfolio.css';
import '../../css/sigma.scss';
import moment from 'moment';

const Navigation = ({ authUser, ...props }) => {
  return (
    <AuthUserContext.Consumer>
      {({ authUser, userData }) => (
        <NavigationAuth authUser={authUser} userData={userData} {...props} />
      )}
    </AuthUserContext.Consumer>
  );
};

const StyledLink = styled(({ underline, ...rest }) => <Link {...rest} />)`
  color: white;
  text-decoration: none;
  cursor: pointer;
  ${({ underline }) => (underline ? 'opacity: 1' : 'opacity: 0.5')}
`;

const Money = styled.span`
  ${({ money }) => (money < 0 ? 'color: red' : 'color: white')};
  font-weight: 400;
`;

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
};

class NavigationAuth extends Component {
  state = {
    anchorEl: null,
    scrollOnTop: true
  };
  catchScroll = () => {
    const { scrollTop } = document.querySelector('html');
    if (scrollTop) {
      this.setState({ scrollOnTop: false });
    } else {
      this.setState({ scrollOnTop: true });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.catchScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.catchScroll);
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    setTimeout(() => this.setState({ anchorEl: null }), 100);
  };
  render() {
    const { authUser, classes, firebase, userData = {} } = this.props;
    const { anchorEl } = this.state;
    const admin = isAdmin(userData);
    const style =
      this.state.scrollOnTop && window.location.pathname === '/'
        ? { background: 'transparent', boxShadow: 'none' }
        : { background: 'rgb(199, 19, 9)' };
    const position = window.location.pathname === '/' ? 'fixed' : 'static';
    return (
      <AppBar position={position} style={style}>
        <Toolbar>
          <IconButton
            onClick={this.handleClick}
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
          >
            <MenuIcon aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" />
          </IconButton>
          <Link to={routes.HOME} style={{ width: '50px' }}>
            <img
              style={{ width: '50px' }}
              src={withPrefix('assets/img/volovod-logo.svg')}
              alt="Volovod"
            />
          </Link>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            {admin && (
              <MenuItem onClick={this.handleClose}>
                <Link to={routes.CALENDAR}>Календар</Link>
              </MenuItem>
            )}
            {admin && (
              <Fragment>
                <MenuItem onClick={this.handleClose}>
                  <Link to={routes.LOGS}>Журнал доходів</Link>
                </MenuItem>
                <hr />
              </Fragment>
            )}
            <Link to={routes.TEAM}>
              <MenuItem onClick={this.handleClose}>Про нас</MenuItem>
            </Link>
            <Link to={routes.TRAININGS}>
              <MenuItem onClick={this.handleClose}>Тренування</MenuItem>
            </Link>
            <Link to={routes.RATING}>
              <MenuItem onClick={this.handleClose}>Рейтинг</MenuItem>
            </Link>
            <Link to={routes.EVENTS}>
              <MenuItem onClick={this.handleClose}>Змагання</MenuItem>
            </Link>
            <Link to={routes.ACCOUNT}>
              <MenuItem onClick={this.handleClose}>Поповнити рахунок</MenuItem>
            </Link>
            {/*<a href="https://sis.in.ua">*/}
            {/*  <MenuItem onClick={this.handleClose}>Магазин SiS</MenuItem>*/}
            {/*</a>*/}
            {/*<a href="https://cyclinghouse.com.ua">*/}
            {/*  <MenuItem onClick={this.handleClose}>Веломайстерня</MenuItem>*/}
            {/*</a>*/}
            {/*<Link to={routes.RENT}>*/}
            {/*  <MenuItem onClick={this.handleClose}>Прокат велосипедів</MenuItem>*/}
            {/*</Link>*/}

            <Link to={routes.theory}>
              <MenuItem onClick={this.handleClose}>Як їздити в групі</MenuItem>
            </Link>
            <a href="http://sis.in.ua/nutrition">
              <MenuItem onClick={this.handleClose}>Гайд з харчування</MenuItem>
            </a>

            <hr />
            <Link to={routes.CONTACTS}>
              <MenuItem onClick={this.handleClose}>Контакти</MenuItem>
            </Link>
            <Link to={routes.SIGN_IN}>
              <MenuItem onClick={this.handleClose}>Наші правила</MenuItem>
            </Link>
          </Menu>
          <Typography variant="h6" color="inherit" className={classes.grow} />

          {authUser ? (
            <Fragment>
              <span> </span>
              <Typography
                style={{ paddingRight: 10 }}
                variant="button"
                color="inherit"
                onClick={firebase.doSignOut}
              >
                {authUser.displayName && authUser.displayName.split(' ')[0]}
                <span> </span>
                <Money money={userData.money}>
                  {userData.money || 0} грн
                  {userData.abonement &&
                    '  до ' + moment(userData.abonement.toDate()).format('DD MMM')}
                </Money>
              </Typography>
              <Link to={routes.ACCOUNT}>
                {' '}
                <Avatar
                  src={
                    userData.photoURL ||
                    'https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/1872865910.png?alt=media&token=2408952e-1559-42ea-be43-e01182434578'
                  }
                />
              </Link>
            </Fragment>
          ) : (
            <StyledLink to={routes.SIGN_IN}>Вхід</StyledLink>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withFirebase(Navigation));
